export const htmlVariables = () => {
    const setHeightVariable = () => {
        document
            .querySelector(':root')
            .style.setProperty('--vh', `${window.innerHeight}px`);
    };
    setHeightVariable();
    window.addEventListener('resize', () => {
        setHeightVariable();
    });
};
