import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { Component } from '@/scripts/utils/component.js';

gsap.registerPlugin(ScrollToPlugin);

export const Hero = Component('.js-hero', (element) => {
    const targetTransitionElement = element.querySelector(
        '.js-target-transition'
    );
    const circles = element.querySelector('.js-circle-layer');
    targetTransitionElement.addEventListener('transitionend', () => {
        circles.style.display = 'none';
    });
    window.addEventListener('load', () => {
        element.classList.add('reveal');
    });
});
