import { Hero } from '@/scripts/components/Hero.js';
import { htmlVariables } from '@/scripts/utils/htmlVariables.js';
import { loadJuveHeader } from '@/scripts/utils/loadJuveHeader.js';

htmlVariables();
Hero();
// Countdown();
// Phases();
// Stadium();
// Faq();
// Rates();
// Products();
// JuventusCard();
// Modal();
// setUpAnchors();
loadJuveHeader();
// loadJuveFooter();
// initModals();
// checkLandingAnchor();
