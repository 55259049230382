/**
 * @param {string} cname
 * @param {string} cvalue
 * @param {number} exdays
 */
export const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    // eslint-disable-next-line unicorn/no-document-cookie
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

/**
 * @param {string} cname
 * @return {string|null}
 */
export const getCookie = (cname) => {
    const name = `${cname}=`;
    const ca = document.cookie.split(';');
    for (let c of ca) {
        while (c.charAt(0) === ' ') {
            c = c.slice(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
};
