import { getCurrentLang } from '@/scripts/utils/currentLang.js';
import { getCookie } from '@/scripts/utils/cookies.js';

const setJuveLoginStatus = () => {
    const cookieTokenKey = 'jcom_acc_tkn';
    const token = getCookie(cookieTokenKey);
    const currentLang = getCurrentLang();

    const baseAccountURL = `https://www.juventus.com/${currentLang}/api/v1/account`;
    const redirectURL = `https://abbonamenti.juventus.com/${currentLang}`;

    if (token === null) {
        window.header.applyAnonymousSetup({
            loginUrl: `${baseAccountURL}/login?stp=${redirectURL}`,
        });
    } else {
        window.header.applyLoggedSetup({
            logoutUrl: `${baseAccountURL}/logout?stp=${redirectURL}`,
            profileUrl: `${baseAccountURL}/userprofile`,
            avatarUrl: `https://account.juventus.com/storage/images/download/preferred?access_token=${token}`,
        });
    }
};

const addBaseUrlToSearchFormAction = () => {
    const searchForm = document.querySelector('#header_searchbar');
    if (!searchForm) return;
    const formActionUrl = new URL(searchForm.action);
    searchForm.action = `https://www.juventus.com${formActionUrl.pathname}`;
};

export const loadJuveHeader = () => {
    $(() => {
        if (window.location.search.indexOf('appview=true') < 1) {
            $('#includedHeader').load(
                `https://www.juventus.com/${getCurrentLang()}/thirdparties/_libraries/_header_v2`,
                () => {
                    $(document).trigger('d3-header-loaded');
                    setTimeout(() => {
                        setJuveLoginStatus();
                        addBaseUrlToSearchFormAction();
                    }, 100);
                }
            );
        }
    });
};
